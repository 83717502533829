import sectionBackground from './background.svg'

import logo from './logo-white.svg'
import logoDark from './logo_dark.png'

import aboutUs from './about-us.svg'
import security from './security.svg'

import features1 from './features-1.png'
import features2 from './features-2.png'
import features3 from './features-3.png'

import frame from './frame.png'
import iphone from './t.png'

import hero from './hero.png'

import iconApple from './icon-apple-white.svg'
import iconPlay from './icon-play.svg'

import iconFeaturesEvents from './icon-features-events.svg'
import iconFeaturesExclusivity from './icon-features-exclusivity.svg'
import iconFeaturesGroups from './icon-features-groups.svg'
import iconFeaturesNotification from './icon-features-notification.svg'
import iconFeaturesReduce from './icon-features-reduce.svg'

const images = {
  logo,
  logoDark,
  sectionBackground,
  aboutUs,
  security,
  features1,
  features2,
  features3,
  hero,
  frame,
  iphone,
  iconApple,
  iconPlay,
  iconFeaturesEvents,
  iconFeaturesExclusivity,
  iconFeaturesGroups,
  iconFeaturesNotification,
  iconFeaturesReduce
}

export default images
