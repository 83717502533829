import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from "prop-types";

AppFooter.propTypes = {
  t: PropTypes.func
}

function AppFooter(props) {
  const currentYear = new Date().getFullYear()
  const { t } = props;
  return (
    <footer className="app-footer">
      <div className="app-footer-copyright">
        <div className="container">
          © {currentYear} All rights reserved. &nbsp;
          <a href={"/legal/privacy"}>{t("terms.privacyTitle")}</a> &nbsp;
          <a href={"/legal/terms"}>{t("terms.termsTitle")}</a> &nbsp;
          <a href={"/legal/disclaimer"}>{t("terms.disclaimerTitle")}</a> &nbsp;
          <a href={"/legal/cookies"}>{t("terms.cookiesTitle")}</a>
        </div>
      </div>
    </footer>
  )
}

export default withTranslation()(AppFooter)
