import React, { Component } from "react";
import { useForm, useField } from "react-form";
import PhoneInput from 'react-phone-input-2';
import {compose} from "redux";
import {Button, WithAuth} from "../../../components";
import 'react-phone-input-2/lib/style.css';
import styles from "./form.module.scss";
import OrganizerService from "../../../services/organizer";
import {toast} from "react-toastify";
import InputMask from "react-input-mask";
class New extends Component {

    DefaultField  = (props) => {
        const { meta: { error }, getInputProps  } = useField(props.name, {
            validate: v => v.length===0||v===-1 ? 'Field ' + props.name + ' is required' : false, defaultValue: props.default||''
        });
        const { type = 'text' } = props;

        const inputProps = getInputProps();
        return (
            <div>
                {type === 'text' &&
                    <input {...inputProps} placeholder={props.placeholder}/>
                }
                {type === 'select' &&
                    <select {...inputProps}>
                        {props.children}
                    </select>
                }
                {type === 'textarea' &&
                    <textarea {...inputProps} placeholder={props.placeholder}/>
                }
                {type === 'mask' &&
                    <InputMask {...inputProps} mask={props.mask} placeholder={props.placeholder} maskChar={null} />
                }
                {error &&
                    <span className={styles.error}>*{error}</span>
                }
            </div>
        )
    }

    PhoneField = (props) =>  {
        const { meta: { error }, getInputProps  } = useField(props.name, {
            validate: v => v===undefined || v.length<=10 ? 'Field ' + props.name + ' is required' : false, defaultValue: ''
        });
        const inputProps = getInputProps();
        return(
            <div>
            <PhoneInput country={'us'} value={inputProps.value} onChange={e=>inputProps.onChange({target:{value:e.replace(/\D/g, '')}})} />
                {error &&
                    <span className={styles.error}>*{error}</span>
                }
            </div>
        )
    }


    NewForm = (props) => {
        const {
            Form,
        } = useForm({
            onSubmit: async (values, instance) => {
                values.sendMeDeals = true;
                values.gender = +values.gender;
                try {
                    if(this.props.data){
                        await OrganizerService.saveUserOrganizerInvite(values, this.props.data.id);
                    }else{
                        await OrganizerService.saveUserOrganizerInvite(values);
                    }
                    toast.success("Successful "+(this.props.data?"updated":" created"));
                    if(!this.props.data)
                        instance.reset();
                }catch(e){
                    toast.error(e.cMessage);
                }
            },
            defaultValues: this.props.data
        });
        return(
            <Form>{props.children}</Form>
        )
    }
    render() {
        const { DefaultField, NewForm, PhoneField } = this;
        return(
            <div className={styles.root}>
                <NewForm>
                    <h2>Add new</h2>
                    <div className={"row "+styles.row}>
                        <div className={"col-sm-4"}>
                            <DefaultField placeholder={"Name"} name={"fullName"}/>
                        </div>
                        <div className={"col-sm-4"}>
                            <DefaultField placeholder={"Email"} name={"email"}/>
                        </div>
                        <div className={"col-sm-2"}>
                            <DefaultField type={"select"} name={"gender"} default={-1}>
                                <option value={-1} disabled selected>Gender</option>
                                <option value={0}>Male</option>
                                <option value={1}>Female</option>
                            </DefaultField>
                        </div>
                        <div className={"col-sm-2"}>
                            <DefaultField type={"select"} name={"locale"} default={-1}>
                                <option value={-1} disabled selected>Language</option>
                                <option value={"en-US"}>English</option>
                                <option value={"pt-BR"}>Portuguese</option>
                            </DefaultField>
                        </div>
                    </div>
                    <div className={"row "+styles.row}>
                        <div className={"col-sm-4"}>
                            <PhoneField name={"phoneNumber"}/>
                        </div>
                        <div className={"col-sm-4"}>
                            <DefaultField placeholder={"City"} name={"city"}/>
                        </div>
                        <div className={"col-sm-2"}>
                            <DefaultField type={"mask"} mask={"aa"} placeholder={"Country"} name={"country"}/>
                        </div>
                        <div className={"col-sm-2"}>
                            <DefaultField type={"select"} name={"appVersion"} default={-1}>
                                <option value={-1} disabled selected>App Version</option>
                                <option value={"ios"}>iOS</option>
                                <option value={"android"}>Android</option>
                            </DefaultField>
                        </div>
                    </div>
                    <div class={"row "+styles.row}>
                        <div className={"col-sm-4"}>
                            <DefaultField type={"textarea"} name={"notes"} placeholder={"Notes"}/>
                        </div>
                        <div className={"col-sm-3"}>
                            Latest activity:<br/>
                            none
                        </div>
                        <div className={"col-sm-3"}>
                            <DefaultField type={"mask"} mask={"99-99-9999"} name={"birthdate"} placeholder={"Birthdate (mm-dd-yyyy)"}/>
                        </div>
                        <div className={"col-sm-2"}>
                            <DefaultField type={"select"}  name={"userType"} default={-1}>
                                <option value={-1} selected disabled>User Type</option>
                                <option value={'user'}>User</option>
                                <option value={'organizer'}>Organizer</option>
                            </DefaultField>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <Button type="submit" className={styles.save}>Save</Button>
                            <Button className={styles.cancel} onClick={_=>this.props.history.push("/admin/organizers")}>Cancel</Button>
                        </div>
                    </div>
                </NewForm>
            </div>
        )
    }
}
export default compose(WithAuth)(New);