import React, { Component } from 'react'
import styles from './admin.module.scss'
import { UserService } from 'services'
import {Button, Icon, List, WithAuth} from 'components'
import { toast } from 'react-toastify'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

class Admin extends Component{

    constructor(props){
        super(props);
        this.state = {
            users: [],
            search: ''
        }
    }

    fetchData = async (filter=[]) =>{
        const { t } = this.props;
        try{
            const users = await UserService.getUsers(filter);
            console.log(users);
            this.setState({users});
        }catch(error){
            toast.error(t('notifications.text.unexpected'))
        }
    }

    search = async () => {
        await this.fetchData([{name: 'name', value: this.state.search}]);
    }

    componentDidMount() {
        this.fetchData().then(_=>{})
    }

    render(){
        return(
            <div className={styles.admin}>
                <div className={"row"}>
                    <div className={"col-sm-6 col-xs-12"}>
                        <input placeholder={"Filter by name or email"} value={this.state.search} onChange={evt=>this.setState({search: evt.target.value})} className={styles.searchBox}/>
                        <div className={styles.search} onClick={this.search}>
                            <Icon name={"search"} style={{marginLeft: 20}} className={"search"}/>
                        </div>
                    </div>
                    <div className={"col-sm-6 col-xs-12"}>
                        <Button onClick={_=>this.props.history.push("/admin/organizers/new")} className={"pull-right"}>Add new</Button>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <List isLoading={false} items={this.state.users} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(compose(WithAuth)(Admin))
