import React from 'react'
import { DownloadApp } from 'components'
import { useTranslation } from 'react-i18next'
import { Route } from 'react-router-dom'
import Privacy from "./Privacy";
import Terms from "./Terms";
import Disclaimer from "./Disclaimer";
import Cookies from "./Cookies";
const LegalRoutes = () => {

    const { t } = useTranslation()

    return (
        <div className="privacy-page-container">
            <div>
                <div className={"borderRainbow"}></div>

                <div className="container">
                    <Route path={"/legal/privacy"} component={Privacy} />
                    <Route path={"/legal/terms"} component={Terms} />
                    <Route path={"/legal/disclaimer"} component={Disclaimer} />
                    <Route path={"/legal/cookies"} component={Cookies} />
                </div>
                <DownloadApp
                    title={t('tutorial.download.title')}
                    content={t('tutorial.download.content')}
                />

            </div>
        </div >
    )
}

export default LegalRoutes
