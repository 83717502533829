import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { Home, Legal, Report, Tutorial, Admin, Places, OrganizerRequest } from 'containers'
import { AppLayout } from 'components'
import { useTranslation } from 'react-i18next'
import New from '../../containers/Admin/forms/New';
import Edit from '../../containers/Admin/forms/Edit';

const AppRouter = () => {
  const { t } = useTranslation()

  const homeRoutes = [
    { label: 'Home', href: '/' },
    { label: t('routes.features'), href: '/#features' },
  ]

  const organizersRoutes = [
    { label: t('routes.private'), href: '#private' },
    { label: t('routes.rating'), href: '#rating' },
    { label: t('routes.invites'), href: '#invites' },
    { label: t('routes.vips'), href: '#vips' },
    { label: t('routes.spots'), href: '#spots' },
    { label: t('routes.ecommerce'), href: '#ecommerce' }
  ]

  const adminRoutes = [
    { label: 'Organizers', href: '/admin/organizers' },
    { label: 'Places', href: '/admin/place' },
    { label: 'Logout', href: '?logout=true' }
  ]

  return (
    <Router>
      <Switch>
        <Route path="/" exact render={props => <AppLayout context="guest" routes={homeRoutes}> <Home {...props} /> </AppLayout>} />
        <Route path="/legal" render={props => <AppLayout context="none" routes={homeRoutes}> <Legal {...props} /> </AppLayout>} />
        <Route path="/report/:uuid" render={props => <AppLayout context="none"> <Report {...props} /> </AppLayout>} />
        <Route path="/organizers" render={props => <AppLayout context="organizer" routes={organizersRoutes}><Tutorial {...props} /></AppLayout>} />
        <Route path="/organizer/applications/:uuid" render={props => <AppLayout context="organizer" router={organizersRoutes}> <OrganizerRequest {...props} /> </AppLayout>} />
        <Route path="/admin/organizers" exact render={props => <AppLayout context="admin" routes={adminRoutes}> <Admin {...props} /></AppLayout> }/>
        <Route path="/admin/organizers/new" exact render={props => <AppLayout context="admin" routes={adminRoutes}> <New {...props} /></AppLayout> }/>
        <Route path="/admin/organizers/:id/edit" render={props => <AppLayout context="admin" routes={adminRoutes}> <Edit {...props} /></AppLayout> }/>
        <Route path="/admin/place" exact render={props => <AppLayout context="admin" routes={adminRoutes}> <Places {...props} /></AppLayout>} />

        <Redirect path="/admin" to="/admin/organizers" />
        <Redirect path="*" to="/" />
      </Switch>
    </Router>
  )
}

export default AppRouter
