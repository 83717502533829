import React from 'react'
import classNames from 'classnames'
import images from 'assets/images'
import PropTypes from 'prop-types'
import { If, MagicLogin } from 'components'
import { OrganizerService } from 'services'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'


class AppNav extends React.PureComponent {
  static propTypes = {
    routes: PropTypes.array,
    context: PropTypes.oneOf([
      'organizer',
      'guest',
      'admin',
      'none'
    ]).isRequired
  }

  state = {
    expanded: false
  }

  /**
   * @description Toggle navigation for mobile devices
   * @return { Void }
   */
  handleExpand = () => {
    this.setState(prevState => ({
      expand: !prevState.expand
    }))
  }

  handleMagicLinkSubmit = async email => {
    const { t } = this.props
    try {
      const data = await OrganizerService.loginAsOrganizer(email)
      toast.success(t('notifications.text.sent'))
      return data
    } catch (error) {
      toast.error(error.cMessage || t('notifications.text.unexpected'))
    }
  }


  render() {
    const { routes } = this.props
    const collapseListClassNames = classNames(
      'collapse navbar-collapse',
      { 'show': this.state.expand }
    )

    return (
      <nav className="app-nav navbar navbar-expand-lg navbar-dark bg-transparent">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img className="navbar-brand-logo" src={images.logo} alt="Let's Party Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            aria-expanded={this.state.expanded}
            aria-label="Toggle navigation"
            onClick={this.handleExpand}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={collapseListClassNames}>
            <ul className="navbar-nav ml-auto">
              {routes && routes.map(route =>
                <li className="nav-item" key={route.label}>
                  <a className="nav-link" href={route.href}>
                    {route.label}
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
              )}
              <li>
                <If test={this.props.context === "guest"}>

                      <MagicLogin onSubmit={this.handleMagicLinkSubmit} />

                </If>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}


export default withTranslation()(AppNav)