import React from 'react'
import styles from './magic-login.module.scss'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class MagicLogin extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  static propTypes = {
    onSubmit: PropTypes.func,
    t: PropTypes.func
  }

  state = {
    email: '',
    show: false
  }

  componentDidUpdate() {
    this.input.current && this.input.current.focus()
  }

  handleClick = () => {
    this.setState({ show: true })
  }

  handleBlur = () => {
    this.formIsValid || this.setState({ show: false })
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOnSubmit = (e) => {
    e.preventDefault()
    const { email } = this.state

    this.props.onSubmit && this.props.onSubmit(email)
    this.setState({ email: '' })
  }

  get formIsValid() {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const { email } = this.state

    return email && emailRegex.test(email) && true
  }

  render() {
    const { show } = this.state
    const { t } = this.props

    return (
      <div className={styles.root}>
        {show
          ? (
            <form onSubmit={this.handleOnSubmit} className={styles.form}>
              <div className="input-group">
                <input
                  ref={this.input}
                  value={this.state.email}
                  className={[styles.input, 'form-control'].join(' ')}
                  type="email"
                  name="email"
                  placeholder={t('form.email')}
                  onChange={this.handleInputChange}
                  onBlur={this.handleBlur}
                />
                <Button disabled={!this.formIsValid} theme="light">
                  Submit
                </Button>
              </div>
            </form>
          )
          : (
            <Button onClick={this.handleClick} className={styles.button} theme="light">
              {t('magicLink.button.text')}
            </Button>
          )
        }
      </div>
    )
  }
}

export default withTranslation()(MagicLogin)
