import React from 'react'
import PropTypes from 'prop-types'
import styles from './device.module.scss'

Device.propTypes = {
  children: PropTypes.node
}

function Device({ children }) {
  return (
    <div className={styles.root}>
      {children}
    </div>
  )
}

export default Device
