import React from 'react';
import { OrganizerService } from 'services';
import { withTranslation } from 'react-i18next'
import { Title } from 'components'
import { toast } from 'react-toastify'

class OrganizerRequest extends React.Component {
    static propTypes = {

    }

    constructor(props) {
        super(props);
        this.state = {
            used: false
        }
    }

    async componentDidMount() {
        const { t } = this.props;
        const uuid = this.props.match.params.uuid
        try {
            let response = await OrganizerService.confirmOrganizerRequest(uuid)
            this.setState({
                used: response.status === 409 ? true : false
            })
        } catch (error) {
            toast.error(error.cMessage || t('notifications.text.unexpected'))
        }
    }

    render() {

        return (
            <div className="root">
                <section className="p-0">
                    <div className="titleContainer" >
                        <Title theme="white">Hello, your organizer request is being processed, you will be notified when we finish.</Title>
                    </div>
                </section>
            </div>
        )

    }
}

export default withTranslation()(OrganizerRequest)