import React from 'react'
import PropTypes from 'prop-types'
import styles from './with-auth.module.scss'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { login, logout } from 'store/auth/actions'
import { GoogleLogin } from 'react-google-login'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
const WithAuth = Component => {
  const WithAuthComponent = props => {
    const { login, logout, loading, failed, ...rest } = props;
    let { isAuth } = props;
    const { t } = useTranslation()

    if (failed) {
      toast.error(failed || t('notifications.text.unexpected'))
    }

    const onSuccess = googleUser => login(googleUser.getAuthResponse().id_token);

    if(props.location.search === '?logout=true'){
      isAuth = false;
      logout();
      props.history.push('/admin');
    }

    if (isAuth) {
      return (
        <div className={styles.root}>
          <div className="container">
            <div className="row">
              <div className="col">
                <Component {...rest} />
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.root}>
        <GoogleLogin
          className={styles.loginButton}
          color="#fff"
          isLoading={loading}
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText={t('buttons.googleLogin')}
          onSuccess={onSuccess}
        />
      </div>
    )
  }

  WithAuthComponent.propTypes = {
    login: PropTypes.func,
    logout: PropTypes.func,
    isAuth: PropTypes.bool,
    failed: PropTypes.string,
    loading: PropTypes.bool,
    rest: PropTypes.object
  }

  return WithAuthComponent
}

WithAuth.propTypes = {
  children: PropTypes.element
}

const mapDispatchToProps = {
  login,
  logout
}

const mapStateToProps = ({ authState }) => ({
  isAuth: authState.isAuth,
  loading: authState.loading,
  failed: authState.failed
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WithAuth,
)
