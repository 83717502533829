import React from 'react'
import PropTypes from 'prop-types'

TermBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

function TermBox(props) {
  return (
    <div className="term-box-container">
      <h2>{props.title}</h2>
      <h3>{props.subtitle}</h3>
      <div className="term-box-content">{props.children}</div>
    </div>
  )
}

export default TermBox
