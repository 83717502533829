import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components'
import * as JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import format from 'date-fns/format'

class Tools extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }

    this.donwloadCSV = this.donwloadCSV.bind(this)
    this.donwloadPDF = this.donwloadPDF.bind(this)
  }

  static propTypes = {
    report: PropTypes.object
  }

  formatDate(date) {
    return format(date, 'H:mm - MMMM/D/YYYY')
  }

  copyUrl() {
    let temp = document.createElement('input')

    document.body.appendChild(temp)
    temp.value = window.location.href
    temp.select()
    document.execCommand('copy')
    document.body.removeChild(temp)
  }

  donwloadCSV() {
    let csvContent, encodedUri, link

    csvContent = 'data:text/csv;charset=utf-8,'

    this.props.report.lists.forEach((list, index, lists) => {
      if (index === 0) {
        csvContent += 'List, Name, Time in, Time out \r\n'
      }
      list.attendees.forEach((attendee) => {
        csvContent += `${list.description}, ${attendee.user.fullName}, ${this.formatDate(attendee.arrivalDatetime)}, ${this.formatDate(attendee.leavingDatetime)}\r\n`
      })
    })

    encodedUri = encodeURI(csvContent)
    link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'Event.csv')
    document.body.appendChild(link)

    link.click()
  }

  donwloadPDF() {
    this.setState({loading: true})

    html2canvas(document.querySelector('#root'), {
      logging: false,
      windowWidth: '1200px',
      onclone: (clone) => {
        clone.getElementsByClassName('app-container')[0].classList.add('download-pdf')
      }
    }).then(canvas => {
      let pdf = new JsPDF('p', 'mm', 'a4')
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 220, 300)
      pdf.save('event.pdf')
      this.setState({loading: false})
    })
  }

  render() {
    return (
      <div className="tools">
        <div className='container'>
          <div className="tools-text">
            <div className="tools-text-title">We hope you have enjoyed your party.</div>
            <div>Here is a list of all guests whose presence was confirmed by geolocation.</div>
          </div>
          <div className="tools-btns">
            <button className="tools-btn" onClick={this.copyUrl}>
              <span className="tools-btn-text">copy url</span>
              <Icon name="link" />
            </button>
            <button className="tools-btn" onClick={this.donwloadPDF}>
              <span className="tools-btn-text">donwload pdf</span>
              <Icon name={ this.state.loading ? 'spinner' : 'arrow-down'} spin={this.state.loading} />
            </button>
            <button className="tools-btn" onClick={window.print}>
              <span className="tools-btn-text">print</span>
              <Icon name="print" />
            </button>
            <button className="tools-btn" onClick={this.donwloadCSV}>
              <span className="tools-btn-text">export CSV</span>
              <Icon name="file-excel-o" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Tools
