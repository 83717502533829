import React, { useEffect, useState } from 'react'
import styles from './tutorial.module.scss'
import enImages from 'assets/images/features/en'
import ptImages from 'assets/images/features/pt'
import Confetti from 'react-dom-confetti'
import { compose } from 'redux'
import i18next from 'i18next'
import {
  Hero,
  DownloadApp,
  Banner,
  Device,
} from 'components'
import { useTranslation } from 'react-i18next'
import Slider from "react-slick"
import WithLinkAuth from "../../components/WithLinkAuth";

const confettiConfig = {
  angle: '270',
  spread: '360',
  startVelocity: '10',
  elementCount: '150',
  dragFriction: '0.02',
  duration: '30000',
  delay: 0,
  width: '10px',
  height: '10px'
}

const Tutorial = (props) => {

  const [active, setActive] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('scroll', onScroll, false)
    return () => window.removeEventListener('scroll', onScroll, false)
  }, [])

  const onScroll = () => {
    const elm = document.getElementById(styles.confetti)

    const rect = elm.getBoundingClientRect()
    const elmBottomOffset = rect.top + rect.height

    if (window.innerHeight > elmBottomOffset + 300) {
      setActive(true)
    }
  }

  const images = i18next.language === "pt-BR" ? ptImages : enImages

  const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{ breakpoint: 600, settings: { arrows: false, dots: true, }  }]
  }

  return (
    <div className={styles.root}>
      <section className="p-0">
        <Hero 
        title={t('tutorial.hero.title')}
        subtitle={t('tutorial.hero.subtitle')}
        downloadTitle={t('tutorial.hero.downloadTitle')}
        buttons  
        bgColor="#341e37" />
      </section>

      <section className="p-0">

          <div className={styles.slideContainer}>
              <Slider {...settings}>
                  {[1,2,3,4,5,6,7,8,9,10].map(i=><img src={images['slide'+i]} key={i} alt={"Banner"} />)}
              </Slider>
          </div>
      </section>

      <section className="bg-white text-dark p-0">
        <div className={[styles.container, 'text-justify'].join(' ')}>
          <div className={styles.text}>
            <h2>{t('tutorial.howWorks1.title')}</h2>
            <p>{t('tutorial.howWorks1.text')}</p>
          </div>
          <div className={styles.imageContainer}>
            <Device>
              <img className={styles.image} src={images.login} alt={"Main screen"} />
            </Device>
          </div>
        </div>
      </section>

      <section className="p-0">
        <Banner
          id="private"
          column
          devices={[images.featurePrivate1, images.featurePrivate2, images.featurePrivate3]}
          title={t('tutorial.banner2.title')}
          text={t('tutorial.banner2.text')}
        />

        <Banner
          id="rating"
          column
          align="right"
          devices={[images.featureInvite1, images.featureInvite2, images.featureInvite3]}
          title={t('tutorial.banner3.title')}
          text={t('tutorial.banner3.text')}
          subText={t('tutorial.banner3.subtext')}
        />

        <div id={styles.confetti}>
          <Confetti active={active} config={confettiConfig} />
        </div>

        <Banner
          id="invites"
          column
          devices={[images.featureMass1, images.featureMass2]}
          title={t('tutorial.banner4.title')}
          text={t('tutorial.banner4.text')}
        />

        <Banner
          id="vips"
          column
          align="right"
          devices={[images.featureVip1, images.featurePrivate1]}
          title={t('tutorial.banner5.title')}
          text={t('tutorial.banner5.text')}
          subText={t('tutorial.banner5.subtext')}
        />

        <Banner
          id="spots"
          column
          devices={[images.featureManage1, images.featureManage2]}
          title={t('tutorial.banner6.title')}
          text={t('tutorial.banner6.text')}
          subText={t('tutorial.banner6.subtext')}
        />

        <Banner
          id="ecommerce"
          column
          align="right"
          devices={[images.feautreEcommerce1, images.feautreEcommerce2]}
          title={t('tutorial.banner7.title')}
          text={t('tutorial.banner7.text')}
        />

        <Banner
          column
          align="center"
          devices={[
            images.featureMore1,
            images.featureMore2,
            images.featureMore3,
            images.featureMore4,
            images.featureMore5,
            images.featureMore6,
            images.featureMore7,
            images.featureManage1
          ]}
          title={t('tutorial.banner8.title')}
        />

      </section>

      {/* <Contact
        onSubmit={handleContactSubmit}
      /> */}

      <DownloadApp
        title={t('tutorial.download.title')}
        content={t('tutorial.download.content')}
        subtitle={t('tutorial.download.subtitle')}
      />
    </div>
  )
}

export default compose(WithLinkAuth)(Tutorial)
