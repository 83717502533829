import fetch from 'isomorphic-fetch'

class LeadService {
  static get = async (page, email = '', pageSize = 10) => {
    const url = `${process.env.REACT_APP_API_URL}/users/leads?pageSize=${pageSize}&page=${page}&email=${email}`
    const token = localStorage.getItem('user')

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      }
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }

  static add = async (email, locale) => {
    const url = `${process.env.REACT_APP_API_URL}/users/leads`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, locale })
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }

  static remove = async id => {
    const url = `${process.env.REACT_APP_API_URL}/users/leads/${id}`
    const token = localStorage.getItem('user')

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${token}`
      }
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }

  static sendLogin = async email => {
    const url = `${process.env.REACT_APP_API_URL}/users/leads/send-login`
    const token = localStorage.getItem('user')

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      },
      body: JSON.stringify({ email })
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }

  static verifyLogin = async token => {
    const url = `${process.env.REACT_APP_API_URL}/users/leads/verify-login`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }
}

export default LeadService
