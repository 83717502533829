import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'components'

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  newPage: PropTypes.bool,
  block: PropTypes.bool,
  theme: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark'
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  outline: PropTypes.bool,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  children: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([
    'white',
    'black'
  ]),
  radius: PropTypes.PropTypes.oneOf([
    '0',
    'circle'
  ]),
  bgColor: PropTypes.string
}

Button.defaultProps = {
  disabled: false,
  newPage: false,
  block: false,
  theme: 'light',
  size: 'medium',
  outline: false
}

function Button(props) {
  const buttonClassName = classNames(
    props.className,
    'app-btn btn',
    { [`btn-${props.theme}`]: !props.outline },
    { [`btn-outline-${props.theme}`]: props.outline },
    { 'btn-block': props.block },
    { 'btn-sm': props.size === 'small' },
    { 'btn-xl': props.size === 'large' },
    { [`color-${props.color}`]: props.color },
    { [`rounded-${props.radius}`]: props.radius }
  )

  const Tag = props.to ? 'a' : 'button'
  const extraProps = props.newPage ? {
    target: '_blank',
    rel: 'noopener noreferrer'
  } : {}

  return (
    <Tag
      disabled={props.disabled}
      href={props.to ? props.to : null}
      className={buttonClassName}
      style={{ backgroundColor: props.bgColor }}
      onClick={props.onClick}
      onBlur={props.onBlur}
      {...extraProps}
    >
      {props.icon && <Icon name={props.icon} />}
      <span className="button-text">{props.children}</span>
    </Tag>
  )
}

export default Button
