import fetch from "isomorphic-fetch";

class OrganizerService { 


  static loginAsOrganizer = async email => {
    const url = `${process.env.REACT_APP_API_URL}/organizers/login`
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({email}),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    if (!response.ok) {
      throw Error(response.statusText)
    }

    const responseTxt = await response.text()
    return responseTxt
  }

  static verifyOrganizerLogin = async token => {
    const url = `${process.env.REACT_APP_API_URL}/organizers/verify`
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({token}),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const responseTxt = await response.text()
    return responseTxt
  }

  static sendOrganizerRequest = async email => {

      const url = `${process.env.REACT_APP_API_URL}/organizers/applications/temp/getUrl/${email}`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })

      if (!response.ok) {
        throw Error(response.statusText)
      }

      const redirectUrl = await response.text()
      return redirectUrl

  }

  static sendInvitationEmail = async(data, id) => {
      let url = `${process.env.REACT_APP_API_URL}/admin/invites/${id}`;
      let method = 'POST';
      const token = localStorage.getItem('user')
      const response = await fetch(url, {
          method,
          headers: {
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
          },
          body: JSON.stringify(data)
      })

      if (response.status === 409) {
          const res = await response.json()
          throw res;
      }

      if (!response.ok) {
          throw Error(response.statusText)
      }

      return await response.json()
  }

  static saveUserOrganizerInvite = async(data, id=null) =>{

      let url = `${process.env.REACT_APP_API_URL}/admin/invites`;
      let method = 'POST';
      if(id!==null){
          url = `${process.env.REACT_APP_API_URL}/admin/user/${id}`;
          method = 'PATCH';
      }
      const token = localStorage.getItem('user')
      const response = await fetch(url, {
          method,
          headers: {
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
          },
          body: JSON.stringify(data)
      })

      if (response.status === 409) {
          const res = await response.json()
          throw res;
      }

      if (!response.ok) {
          throw Error(response.statusText)
      }

      return await response.json()
  }

  static getOrganizers = async (page, email = '', pageSize = 10) => {
      const url = `${process.env.REACT_APP_API_URL}/organizers?pageSize=${pageSize}&page=${page}&name=${email}`
      const token = localStorage.getItem('user')

      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
          }
      })

      if (!response.ok) {
          throw Error(response.statusText)
      }

      const json = await response.json()

      return json
  }

  static getApplications = async (page, email = '', pageSize = 10) => {
      const url = `${process.env.REACT_APP_API_URL}/organizers/applications?pageSize=${pageSize}&page=${page}&email=${email}`
      const token = localStorage.getItem('user')

      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
          }
      })

      if (!response.ok) {
          throw Error(response.statusText)
      }

      const json = await response.json()

      return json
  }

  static confirmOrganizerRequest = async uuid => {

    const url = `${process.env.REACT_APP_API_URL}/temp/${uuid}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })

    if (response.status === 409) {
      return await response.json()
    }

    if (!response.ok) {
      throw Error(response.statusText)
    }

    return await response.json()
  }

  static makeOrganizer = async (userId, workEmail) => {
      const url = `${process.env.REACT_APP_API_URL}/organizers`
      const token = localStorage.getItem('user')
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `bearer ${token}`
          },
          body: JSON.stringify([ { userId, workEmail } ])
      })

      if (response.status === 409) {
          return await response.json()
      }

      if (!response.ok) {
          throw Error(response.statusText)
      }

      return await response.json()
  }

    static deleteApplication = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/organizers/applications/${id}`
        const token = localStorage.getItem('user')
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `bearer ${token}`
            }
        })

        if (response.status === 409) {
            return await response.json()
        }

        if (!response.ok) {
            throw Error(response.statusText)
        }

        return await response.json()
    }

}

export default OrganizerService