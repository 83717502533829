import { call, put, takeLatest } from 'redux-saga/effects'
import { AdminService } from 'services'
import types from 'store/auth/types'

function* googleLogin(action) {
  try {
    const { googleToken } = action.payload
    const response = yield call(AdminService.googleLogin, googleToken)
    localStorage.setItem('user', response.token)

    yield put({ type: types.SUCCESS })
  } catch (e) {
    yield put({
      type: types.FAILURE,
      payload: {
        message: e.message
      }
    })
  }
}

function* authSagas() {
  yield takeLatest(types.LOGIN, googleLogin)
}

export { authSagas }
