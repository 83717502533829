import React from 'react'
import PropTypes from 'prop-types'
import styles from './banner.module.scss'
import {Device, Title} from 'components'

Banner.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  devices: PropTypes.arrayOf(PropTypes.string),
  alt: PropTypes.string,
  text: PropTypes.string,
  column: PropTypes.bool,
  children: PropTypes.element,
  inverse: PropTypes.bool,
  maxWidth: PropTypes.string,
  align: PropTypes.oneOf([
    'right',
    'left',
    'center',
    'justify'
  ])
}

Banner.defaultProps = {
  align: 'left',
  title: ''
}

function Banner({ id, title, subtitle, images, devices, alt, text, subText, column, align, inverse, children }) {
  const order = inverse ? 'order-md-1' : ''
  const direction = column ? 'd-block' : ''
  align = `text-${align}`

  return (
    <div id={id} className={styles.root}>
      <div className={[styles.container, direction, align].join(' ')}>
        <div className={[styles.text, order].join(' ')} >
          {title && <Title theme="white"> {title} <br /> {subtitle}</Title>}
          {text && <p> {text} <br /> {subText} </p>}
          {children}
        </div>
        <div className={styles.imageContainer} >
          {images && images.map((src, i) => {
            return <img key={i} className={styles.image} src={src} alt={alt} />
          }   
          )}
          {devices && devices.map((src, i) => {
            return (
              <div key={i} className={styles.deviceContainer}>
                <Device>
                  <img className={styles.image} src={src} alt={alt} />
                </Device>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Banner
