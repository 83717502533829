import React, { Component } from "react";
import New from "./New";
import {UserService} from 'services'
import { toast } from 'react-toastify'
import moment from "moment";

class Edit extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }

    async componentDidMount() {
        try{
            const data = await UserService.getUser(this.props.match.params.id);
            Object.keys(data).forEach(index=>{
                if(data[index]===null) delete data[index];
            });
            data.userType = 'user';
            if(data.organizer && Object.keys(data.organizer).length>0){
                data.userType = 'organizer';
            }
            data.birthdate = moment(data.birthdate).format("MM-DD-YYYY");
            delete data['organizer'];
            delete data['userInvite'];
            this.setState({data});
        }catch(err){
            console.log(err);
            toast.error('Cannot retrieve information');
        }
    }

    render(){
        return(
            <New data={this.state.data}/>
        )
    }
}

export default Edit;