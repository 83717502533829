import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button
} from 'components'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import enImages from 'assets/images/features/en'
import ptImages from 'assets/images/features/pt'

class OrganizerRequest extends React.Component {

  static propTypes = {
    onSubmit: PropTypes.func
  }

  state = {
    email: '',
  }

  get formIsValid() {
    const { email } = this.state
    return email && email.includes('@') && true
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOnSubmit = (e) => {
    e.preventDefault()

    const { email } = this.state
    this.props.onSubmit && this.props.onSubmit({ email })
  }

  render() {

    const { t } = this.props;
    const images = i18next.language === "pt-BR" ? ptImages : enImages

    return (
      <section id="contact" className="contact-us bg-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-sm-12">
              <div className="text-center">
                <Title theme="white">{t('organizer.title')}</Title>
                <h3>{t('organizer.subtitle')}</h3>
              </div>
            </div>

            <form onSubmit={this.handleOnSubmit}>
              <div className="row">
                <div className="col form-container">
                  <div className="form-group">
                    <div className="row">
                      <div className="col">
                        <p>{t('organizer.formTitle')}</p>
                        <input
                          required
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={this.handleInputChange}
                        />
                        <span className="terms-text">{t('organizer.termsText')}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-6 col-xl-6">
                      <Button disabled={!this.formIsValid} block theme="light">
                        {t('organizer.confirmForm')}
                      </Button>
                    </div>
                  </div>

                </div>
                <div className="col">
                  <img src={images.featureOrganizer} />
                </div>
              </div>
            </form>


          </div>
        </div>
      </section>

    )

  }


}

export default withTranslation()(OrganizerRequest)
