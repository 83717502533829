import types from 'store/auth/types'
import { createReducer } from 'store/utils'

const login = state => {
  return {
    ...state,
    loading: true,
    failed: ''
  }
}

const success = state => {
  return {
    ...state,
    isAuth: true,
    loading: false
  }
}

const failure = (state, action) => {
  const { message } = action.payload
  return {
    ...state,
    loading: false,
    failed: message
  }
}

const logout = state => {
  localStorage.removeItem('user')
  return {
    ...state,
    isAuth: false
  }
}

const handlers = {
  [types.LOGIN]: login,
  [types.LOGOUT]: logout,
  [types.SUCCESS]: success,
  [types.FAILURE]: failure
}

const initialState = {
  isAuth: !!localStorage.getItem('user'),
  loading: false,
  failed: ''
}

export default createReducer(initialState, handlers)
