import React from 'react'
import PropTypes from 'prop-types'

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  spin: PropTypes.bool,
  size: PropTypes.oneOf([2, 3, 5, 7, 9])
}

function Icon(props) {
  const iconClassName = `fa fa-${props.name}`
  const spin = (props.spin) ? 'fa-spin' : ''
  const size = (props.size) ? `fa-${props.size}x` : ''

  return (
    <i className={`${iconClassName} ${spin} ${size}`} />
  )
}

export default Icon
