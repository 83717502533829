import React from 'react'
import PropTypes from 'prop-types'
import { Title, Button } from 'components'

DownloadApp.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}

function DownloadApp(props) {
  const storeButtons = [
    {
      block: true,
      label: 'App Store',
      icon: 'apple',
      theme: 'primary',
      newPage: true,
      to: 'https://apps.apple.com/us/app/lets-party-llc/id1387953745?ls=1'
    },
    {
      block: true,
      label: 'Play Store',
      icon: 'android',
      theme: 'primary',
      outline: true,
      newPage: true,
      to: 'https://play.google.com/store/apps/details?id=vip.yeah.letsparty'
    }
  ]

  return (
    <section id="download" className="download-container">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <Title>{props.title}</Title>
            <div className="stores-buttons-container">
              <div className="row">
                {storeButtons.map(button =>
                  <div key={button.label} className="col-lg-6 col-md-6 col-sm-12">
                    <Button {...button}>{button.label}</Button>
                  </div>
                )}
              </div>
            </div>
            <p>{props.subtitle}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DownloadApp
