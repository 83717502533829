import featurePrivate1 from './feature-private1.png'
import featurePrivate2 from './feature-private2.png'
import featurePrivate3 from './feature-private3.png'

import featureInvite1 from './feature-invite1.png'
import featureInvite2 from './feature-invite2.png'
import featureInvite3 from './feature-invite3.png'

import featureMass1 from './feature-mass1.png'
import featureMass2 from './feature-mass2.png'

import featureVip1 from './feature-vip1.png'

import featureManage1 from './feature-manage1.png'
import featureManage2 from './feature-manage2.png'

import feautreEcommerce1 from './feature-ecommerce1.png'
import feautreEcommerce2 from './feature-ecommerce2.png'

import featureMore1 from './feature-more1.png'
import featureMore2 from './feature-more2.png'
import featureMore3 from './feature-more3.png'
import featureMore4 from './feature-more4.png'
import featureMore5 from './feature-more5.png'
import featureMore6 from './feature-more6.png'
import featureMore7 from './feature-more7.png'

import slide1 from './slide1.jpg'
import slide2 from './slide2.jpg'
import slide3 from './slide3.jpg'
import slide4 from './slide4.jpg'
import slide5 from './slide5.jpg'
import slide6 from './slide6.jpg'
import slide7 from './slide7.jpg'
import slide8 from './slide8.jpg'
import slide9 from './slide9.jpg'
import slide10 from './slide10.jpg'
import slide11 from './slide11.jpg'

import slideg1 from './feature-slideg1.jpg'
import slideg2 from './feature-slideg2.jpg'
import slideg3 from './feature-slideg3.jpg'
import slideg4 from './feature-slideg4.jpg'
import slideg5 from './feature-slideg5.jpg'
import slideg6 from './feature-slideg6.jpg'
import slideg7 from './feature-slideg7.jpg'
import slideg8 from './feature-slideg8.jpg'

import featureOrganizer from './feature-organizer.png'

import login from './feature-login.png'


const images = {
    featurePrivate1,
    featurePrivate2,
    featurePrivate3,

    featureInvite1, 
    featureInvite2,
    featureInvite3,

    featureMass1,
    featureMass2,

    featureVip1,

    featureManage1,
    featureManage2,

    feautreEcommerce1,
    feautreEcommerce2,

    featureMore1, 
    featureMore2, 
    featureMore3, 
    featureMore4, 
    featureMore5, 
    featureMore6, 
    featureMore7,

    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,

    slideg1,
    slideg2,
    slideg3,
    slideg4,
    slideg5,
    slideg6,
    slideg7,
    slideg8,

    featureOrganizer,
    
    login,
}

export default images;