import React from 'react'
import PropTypes from 'prop-types'
import List from './List'

class Lists extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      gender: -1,
      attendeesPerList: 10
    }

    this.onScroll = this.onScroll.bind(this)
  }

  static propTypes = {
    lists: PropTypes.arrayOf(PropTypes.object)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll() {
    if (this.props.lists) {
      let elms = document.getElementsByClassName('lists-tabs')
      let rect = elms.length && elms[0].getBoundingClientRect()
      let elmBottomOffset = rect.top + rect.height

      // Check if scroll has hit bottom of list plus some padding
      // Check if has load whole active list
      if (window.innerHeight > elmBottomOffset + 100 &&
        this.props.lists[this.state.active].attendees.length > this.state.attendeesPerList) {
        this.setState({attendeesPerList: this.state.attendeesPerList + 1})
      }
    }
  }

  onListChange(e) {
    this.setState({active: +e.target.value})
  }

  onGenderChange(e) {
    this.setState({gender: +e.target.value})
  }

  lists() {
    let select
    let lists = []

    select = (
      <select
        className="lists-select"
        onChange={(e) => { this.onListChange(e) }}>
        {this.props.lists.map((list, i) => <option key={i} value={i}>{list.description}</option>)}
      </select>
    )

    lists = this.props.lists.map((list, i) => {
      return (
        <List key={i}
          active={this.state.active === i}
          list={list}
          gender={this.state.gender}
          attendeesPerList={this.state.attendeesPerList} />
      )
    })

    return (
      <React.Fragment>
        <nav>
          <div className="lists-selector">
            <div>Choose a list to view:</div>
            {select}
          </div>
        </nav>
        <div className="lists-selector mt-3">
          <div>Filter by gender: </div>
          <select
            className="lists-select"
            onChange={(e) => { this.onGenderChange(e) }}>
            <option value="-1">All</option>
            <option value="0">Male</option>
            <option value="1">Female</option>
            <option value="2">Other</option>
          </select>
        </div>
        <div className="lists-tabs tab-content">
          {lists}
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <div className="lists">
        <div className='container'>
          {this.props.lists ? this.lists() : ''}
        </div>
      </div>
    )
  }
}

export default Lists
