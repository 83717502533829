import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import Image from 'react-graceful-image'

class Event extends React.Component {
  static propTypes = {
    info: PropTypes.object
  }

  formatDate(date) {
    return format(date, 'MMMM D, YYYY')
  }

  render() {
    return (
      <div className="event">
        <div className="event-container container">
          <Image className="event-image"
            src={this.props.info ? this.props.info.imageUrl : ''}
            width="200"
            height="120"
            placeholderColor="#3f4663"
            alt="Event"/>
          <div className="event-info">
            <div className="event-title">{this.props.info ? this.props.info.eventName : ''}</div>
            <div className="event-location">{this.props.info ? `${this.props.info.locale.name}, ${this.props.info.locale.city}` : ''}</div>
            <div className="event-date">{this.props.info ? this.formatDate(this.props.info.eventDate) : ''}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Event
