import React from 'react'
import styles from './home.module.scss'
import enImages from 'assets/images/features/en'
import ptImages from 'assets/images/features/pt'
import PropTypes from 'prop-types'
import { LeadService, OrganizerService } from 'services'
import { Hero, Banner, DownloadApp } from 'components'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { toast } from 'react-toastify'

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  static propTypes = {
    addNotification: PropTypes.func,
    t: PropTypes.func
  }

  handleOrganizerRequestSubmit = async (request) => {

    const { t } = this.props;
    const { email } = request;

    try { 
      await OrganizerService.sendOrganizerRequest(email)
      toast.success(t('notifications.text.sent'))
    } catch (error) {
      toast.error(error.cMessage || t('notifications.text.emailNotFound', { email: email }))
    }
  }

  handleMagicLinkSubmit = async email => {
    const { t } = this.props
    try {
      const data = await LeadService.sendLogin(email)
      toast.success(t('notifications.text.sent'))
      return data
    } catch (error) {
      toast.error(error.cMessage || t('notifications.text.unexpected'))
    }
  }

  render() {
    const { t } = this.props
    const images = i18next.language === "pt-BR" ? ptImages : enImages

    const titleStyle = {
      margin: "1rem 0rem",
      fontSize: "2rem"
    }
    const settings = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{ breakpoint: 600, settings: { arrows: false, dots: true, }  }]
    }
    return (
      <div className={styles.root}>
        <section className="p-0">
          <Hero
            titleStyle={titleStyle}
            title={t('marketing.hero.title')}
            downloadTitle={t('tutorial.hero.downloadTitle')}
            buttons
            bgColor="#341e37" />
        </section>
        <section className="p-0">
          <div className={styles.slideContainer}>
            <Slider {...settings}>
              {[1,2,3,4,5,6,7,8].map(i=><img src={images['slideg'+i]} key={i} alt={"Banner"} />)}
            </Slider>
          </div>
        </ section>
        <section id="features" className="text-white">
          <div>
            <Banner
              devices={[images.featureVip1]}
              inverse
              align="center"
              title={t('marketing.banner1.title')}
              subtitle={t('marketing.banner1.subtitle')}
              text={t('marketing.banner1.text')}
              alt="banner 1"
            />
            <Banner
              devices={[images.featureMore1]}
              align="center"
              title={t('marketing.banner2.title')}
              subtitle={t('marketing.banner2.subtitle')}
              text={t('marketing.banner2.text')}
              alt="banner 2"
            />
          </div>
        </section>

        <div className={styles.tosContainer}>
          <DownloadApp title="Download" />
        </div>

      </div>
    )
  }
}

export default withTranslation()(Home)
