import React from 'react'
import { Event, Lists, Tools } from './'
import PropTypes from 'prop-types'

class Report extends React.Component {
  static propTypes = {
    match: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      report: {}
    }
  }

  componentDidMount() {
    const uuid = this.props.match.params.uuid

    fetch(`${process.env.REACT_APP_API_URL}/temp/${uuid}`)
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw err })
        }
        return response.json()
      })
      .then(data => {
        data.lists.forEach(list => {
          list.attendees = data.attendees.filter(attendee => {
            return attendee.listId === list.id
          })
        })
        this.setState({report: data})
      })
      .catch(error => {
        this.setState({error: error})
      })
  }

  render() {
    if (this.state.error) {
      return (
        <div className="report">
          <div className="report-error">
            {this.state.error.cMessage}
          </div>
        </div>
      )
    } else {
      return (
        <div className="report">
          <Event info={this.state.report.event} />
          <Tools report={this.state.report} />
          <Lists lists={this.state.report.lists} />
        </div>
      )
    }
  }
}

export default Report
