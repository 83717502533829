import React, { useEffect } from 'react'
import styles from './places.module.scss'
import PropTypes from 'prop-types'
import { List, Button, WithAuth } from 'components'
import { useList } from 'hooks'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { PlaceService } from 'services'
import { toast } from 'react-toastify'

const googleMapsLink = (lat, long) => `https://www.google.com/maps/search/?api=1&query=${lat},${long}`

const PlaceItem = ({ place, icon, onClick }) => {
  const { name, address, city, state, latitude, longitude } = place
  return (
    <li className="text-white d-flex align-items-center flex-nowrap">
      <Button className="mr-3" icon={icon} onClick={onClick} />
      <div>
        <a className="text-info" href={googleMapsLink(latitude, longitude)} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
        <br /> {address} <br /> {city} {state}
      </div>
    </li>
  )
}

PlaceItem.propTypes = {
  place: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.string
}

const Places = () => {
  const [verified, verifiedSetters] = useList()
  const [unVerified, unVerifiedSetters] = useList()
  const { t } = useTranslation()

  useEffect(() => {
    const readData = async () => {
      try {
        const data = await PlaceService.read(verified.page)
        verifiedSetters.setItems(data.results || [])
        verifiedSetters.setTotal(data.total)
      } catch (error) {
        toast.error(error.cMessage || t('notifications.text.unexpected'))
      }
    }
    readData()
  }, [verified.page])

  useEffect(() => {
    const readData = async () => {
      try {
        const data = await PlaceService.read(unVerified.page, false)
        unVerifiedSetters.setItems(data.results || [])
        unVerifiedSetters.setTotal(data.total)
      } catch (error) {
        toast.error(error.cMessage || t('notifications.text.unexpected'))
      }
    }
    readData()
  }, [unVerified.page])

  const onItemClick = async place => {
    try {
      const data = await PlaceService.update(place.id, { isVerified: !place.isVerified })
      if (data.isVerified) {
        unVerifiedSetters.setItems(unVerified.items.filter(item => item.id !== place.id))
        verifiedSetters.setItems([data, ...verified.items])
      } else {
        verifiedSetters.setItems(verified.items.filter(item => item.id !== place.id))
        unVerifiedSetters.setItems([data, ...unVerified.items])
      }
    } catch (error) {
      toast.error(error.cMessage || t('notifications.text.unexpected'))
    }
  }

  const verifiedItems = verified.items.map(place => (
    <PlaceItem key={place.id} place={place} icon="times" onClick={() => onItemClick(place)} />
  ))
  const unVerifiedItems = unVerified.items.map(place => (
    <PlaceItem key={place.id} place={place} icon="check" onClick={() => onItemClick(place)} />
  ))

  return (
    <div className={styles.root}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="text-white"> {t('VERIFIED')} </h2>
            <List
              items={verifiedItems}
              page={verified.page}
              total={verified.total}
              next={() => verifiedSetters.setPage(verified.page + 1)}
              prev={() => verifiedSetters.setPage(verified.page - 1)}
            />
          </div>
          <div className="col">
            <h2 className="text-white"> {t('UNVERIFIED')} </h2>
            <List
              items={unVerifiedItems}
              page={unVerified.page}
              total={unVerified.total}
              next={() => unVerifiedSetters.setPage(unVerified.page + 1)}
              prev={() => unVerifiedSetters.setPage(unVerified.page - 1)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(WithAuth)(Places)
