import fetch from "isomorphic-fetch";

export default class UserService {
    static getUsers = async (filters = []) => {
        let filterBuild = "";
        filters.forEach(filter=>{
            filterBuild += `${filter.name}=${filter.value}&`;
        })
        const url = `${process.env.REACT_APP_API_URL}/admin/users?${filterBuild}`;
        const token = localStorage.getItem('user')

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `bearer ${token}`
            }
        })

        if (!response.ok) {
            throw Error(response.statusText)
        }

        const json = await response.json()

        return json
    }

    static getUser = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/admin/user/${id}`;
        const token = localStorage.getItem('user');

        const response = await fetch(url,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `bearer ${token}`
            }
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }

        const json = await response.json()

        return json

    }

    static deleteUser = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/admin/user/${id}`;
        const token = localStorage.getItem('user');

        const response = await fetch(url,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `bearer ${token}`
            }
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }

        const json = await response.json()

        return json

    }


}
