import React, { useState } from 'react' // eslint-disable-line no-unused-vars

const useList = () => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  return [{ items, page, total }, { setItems, setPage, setTotal }]
}

export default useList
