import React from 'react'
import PropTypes from 'prop-types'
import { AppNav, AppFooter } from 'components'

AppLayout.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.array,
  context: PropTypes.oneOf([
    'organizer',
    'guest',
    'admin'
  ]).isRequired
}

function AppLayout({children, routes, context}) {
  return (
    <div className="app-container">
      <AppNav routes={routes} context={context}/>
        <div className="page-content">{children}</div>
      <AppFooter />
    </div>
  )
}

export default AppLayout
