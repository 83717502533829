import React from 'react'
import PropTypes from 'prop-types'

Title.propTypes = {
  theme: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'danger',
    'info',
    'light',
    'white'
  ]),
  titleStyle: PropTypes.object
}

Title.defaultProps = {
  theme: 'default'
}

function Title(props) {
  const titleClassName = `app-title text-${props.theme}`
  const hrClassName = `app-title-separator border-${props.theme}`

  return (
    <h2 className={titleClassName} style={props.titleStyle}>
      {props.children}
      <hr className={hrClassName} />
    </h2>
  )
}

export default Title
