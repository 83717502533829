import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { AppRouter } from 'components'
import { ToastContainer } from 'react-toastify'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import rootReducers from 'store/rootReducers'
import rootSagas from 'store/rootSagas'
import 'styles/app.scss'
import './i18n'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducers, {}, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSagas)

ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <ToastContainer />
      <AppRouter />
    </Fragment>
  </Provider>,
  document.getElementById('root')
)
