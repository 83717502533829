import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './with-link-auth.module.scss'
import { OrganizerService } from 'services'
import { MagicLogin } from 'components'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const WithLinkAuth = Component => {
  const WithLinkAuthComponent = props => {
    const { location, ...rest } = props
    const [isAuth, setIsAuth] = useState(false)
    const { t } = useTranslation()

    const handleMagicLinkSubmit = async email => {
      try {
        const data = await OrganizerService.loginAsOrganizer(email)
        toast.success(t('notifications.text.sent'))
        return data
      } catch (error) {
        toast.error(t('notifications.text.unexpected'))
      }
    }

    useEffect(() => {
      const asyncFunc = async () => {
        const params = new URLSearchParams(location.search)
        const token = params.get('token')
        try {
          const data = await OrganizerService.verifyOrganizerLogin(token)
          if (data === 'true') {
            setIsAuth(true)
          }
          return data
        } catch (error) {
          toast.error(error.cMessage || t('notifications.text.unexpected'))
        }
      }
      asyncFunc()
    }, [])

    if (isAuth) {
      return <Component {...rest} />
    }

    return (
      <div className={styles.root}>
        <MagicLogin onSubmit={handleMagicLinkSubmit} />
      </div>
    )
  }
  WithLinkAuthComponent.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }

  return WithLinkAuthComponent
}
WithLinkAuth.propTypes = {
  children: PropTypes.element
}

export default WithLinkAuth
