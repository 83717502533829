import React from 'react'
import PropTypes from 'prop-types'
import styles from './hero.module.scss'
import { Button } from 'components'
import Title from 'components/Title'

class Hero extends React.Component {

    static propTypes = {
        children: PropTypes.node,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        downloadTitle: PropTypes.string,
        color: PropTypes.string,
        bgImage: PropTypes.string,
        bgColor: PropTypes.string,
        buttons: PropTypes.bool,
        titleStyle: PropTypes.object
    }

    render() {

        const buttons = (
            <div className={styles.buttons}>
                <Button to="https://apps.apple.com/us/app/lets-party-llc/id1387953745?ls=1" newPage inline theme="light" icon="apple"> App Store </Button>
                <Button to="https://play.google.com/store/apps/details?id=vip.yeah.letsparty" newPage inline theme="light" icon="android"> Play Store </Button>
            </div>
        )

        const style = {
            backgroundColor: this.props.bgColor
        }

        return (
            <div className={styles.root} style={style}>
                <div className="container">
                    <div className={styles.childrenContainer}>
                        <div className={"text-center"}>
                            <Title theme="white" titleStyle={this.props.titleStyle}>{this.props.title} <br /> {this.props.subtitle}</Title>
                        </div>

                        <div>
                            <p>{this.props.downloadTitle}</p>
                            {this.props.buttons && buttons}
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default Hero