import Report from './Report'
import './report.scss'

import Event from './partials/Event'
import Lists from './partials/Lists'
import Tools from './partials/Tools'

export {
  Event,
  Lists,
  Tools
}

export default Report
