import React, {Component} from 'react'
import styles from './list.module.scss'
import {Icon} from "../index";
import moment from 'moment';
import { Link } from "react-router-dom";
import {OrganizerService, UserService}  from "../../services";
import { toast } from 'react-toastify';
import Confirm from "../Confirm/Confirm";


class List extends Component{

    constructor(props){
        super(props);
        this.state  =  {
            users: []
        }
    }
    resend = async (user) => {
        const { users } = this.state;
        const index = this.state.users.findIndex(i=>i.id===user.id);
        try{
            users[index].userInvite = 'loading';
            this.setState({users});
            const invite = await OrganizerService.sendInvitationEmail({...user, locale: 'en-US'}, user.id);
            users[index].userInvite = invite;
            this.setState({users});
        }catch(e){
            console.log(e);
            toast.error('Something goes wrong');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.items.length!==this.props.items.length)
            this.setState({users: this.props.items})
    }

    invite = async (user) =>{
        const { fullName, email } = user;
        const index = this.state.users.findIndex(i=>i.id===user.id);
        const { users } = this.state;
        try {
            users[index].userInvite = 'loading';
            this.setState({users});
            const invite = await OrganizerService.saveUserOrganizerInvite({fullName, email, locale: 'en-US', userType: 'organizer'});
            users[index].userInvite = invite;
            this.setState({users});
        }catch(e){
            toast.error('Something goes wrong');
        }
    }
    userType = (user) => {
        let type = '';
        if(user.userInvite === 'loading') return 'Loading...';
        if(user.organizer){
            type = 'Organizer';
        }
        if(user.userInvite){
            if(user.userInvite.createdAt>user.lastLogin){
                type = <div>Invited ({moment(user.userInvite.createdAt).format('MM-DD-YYYY')}) - <a onClick={_=>this.resend(user)}>Resend</a></div>
            }
        }else if(!user.organizer){
            type = <div>User - <a onClick={_=>this.invite(user)}>Invite</a></div>
        }
        return type;
    }

    showConfirm = (user) => {
        this.setState({
            confirm: {
                onCancel: _=> { this.setState({confirm: null}) },
                onConfirm: _=> this.deleteUser(user),
                message: `Do you really want to remove ${user.fullName}?`
            }
        })
    }

    deleteUser = async (user) => {
        try{
            const {confirm, users} = this.state;
            confirm.message = 'Removing, please wait...';
            this.setState({confirm});
            await UserService.deleteUser(user.id);
            const findUser = users.findIndex(i=>i.id===user.id);
            delete users[findUser];
            this.setState({confirm: null, users});
        }catch(e){
            toast.error('Something goes wrong');
        }
    }

    render() {
        return (
            <div className={styles.table}>
                {this.props.isLoading&&<span>loading...</span>}
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>User Type</th>
                        <th>City/Country</th>
                        <th>App version</th>
                        <th>Notes</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.users.map(user=>
                        <tr>
                            <td>{user.fullName}</td>
                            <td>{user.email}</td>
                            <td>{user.phoneNumber}</td>
                            <td className={styles.type}>{this.userType(user)}</td>
                            <td>{user.city} - {user.country}</td>
                            <td>1.0.0</td>
                            <td>{user.notes}</td>
                            <td><Link to={`/admin/organizers/${user.id}/edit`}><Icon name={"edit"}/></Link> <a onClick={_=>this.showConfirm(user)}><Icon name={"remove"}/></a></td>
                        </tr>
                    )}
                    </tbody>
                </table>
                {this.state.confirm &&
                    <Confirm {...this.state.confirm} />
                }
            </div>
        )
    }
}

export default List
