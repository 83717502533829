import fetch from 'isomorphic-fetch'
class AdminService {
  static googleLogin = async googleToken => {
    let url = `${process.env.REACT_APP_API_URL}/admin/login`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: googleToken })
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }
}

export default AdminService
