import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

class List extends React.Component {
  constructor(props) {
    super(props)

    this.attendees = this.attendees.bind(this)
  }

  static propTypes = {
    list: PropTypes.object,
    gender: PropTypes.number,
    active: PropTypes.bool,
    attendeesPerList: PropTypes.number
  }

  formatDate(date) {
    return format(date, 'H:mm MMMM D, YYYY')
  }

  attendees() {
    return this.props.list.attendees.reduce((filtered, attendee, x) => {
      if (x < this.props.attendeesPerList && (this.props.gender === -1 || attendee.user.gender === this.props.gender)) {
        filtered.push(
          <tr key={x}>
            <td className="lists-attendee">
              <span className="lists-attendee-date">
                {this.formatDate(attendee.arrivalDatetime)}
              </span>
              <span>{attendee.user.fullName}</span>
              <span className="lists-attendee-date">
                {this.formatDate(attendee.leavingDatetime)}
              </span>
            </td>
          </tr>
        )
      }
      return filtered
    }, [])
  }

  render() {
    return (
      <div className={`tab-pane fade show ${this.props.active ? 'active' : ''}`}
        role="tabpanel">
        <table className="lists-table">
          <thead>
            <tr>
              <th>{this.props.list.description}</th>
            </tr>
          </thead>
          <tbody>
            {this.attendees()}
          </tbody>
        </table>
      </div>
    )
  }
}

export default List
