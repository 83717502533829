import fetch from 'isomorphic-fetch'
class PlaceService {
  static defaultPageSize = 10

  static read = async (page, isVerified = true, pageSize = this.defaultPageSize) => {
    const url = `${process.env.REACT_APP_API_URL}/places?pageSize=${pageSize}&page=${page}&isVerified=${isVerified}`
    const token = localStorage.getItem('user')

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      }
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }

  static update = async (id, place) => {
    const url = `${process.env.REACT_APP_API_URL}/places/${id}`
    const token = localStorage.getItem('user')

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`
      },
      body: JSON.stringify(place)
    })

    if (!response.ok) {
      throw Error(response.statusText)
    }

    const json = await response.json()

    return json
  }
}

export default PlaceService
