import types from './types'

const login = googleToken => ({
  type: types.LOGIN,
  payload: {
    googleToken
  }
})

const logout = () => ({
  type: types.LOGOUT
})

export { login, logout }
