import React, { Component } from "react";
import styles from "./confirm.module.scss";
import {Button} from "../index";

class Confirm extends Component {

    render(){
        console.log(styles);
        return (
            <div className={styles.overflow}>
                <div>
                    <div>
                        <div>
                            {this.props.message}
                            <div>
                                <Button type="submit" onClick={this.props.onCancel} className={styles.cancel}>Cancel</Button>
                                <Button type="submit" onClick={this.props.onConfirm} className={styles.save}>Confirm</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Confirm;